body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-image: url("https://dacq68pa0iusn.cloudfront.net/filters:format(webp)/1920x380/Springbok/WebGraphics/Homepage/footer-bg-cover.jpg");
  background-size: cover;
}

.pass-upate-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 5em;
  box-shadow: 0 0 10px 0 gray;
  width: 500px;
  background-color: white;
  margin: auto;
  border-radius: 15px;
}

.login-signup-wrapper {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
}

a {
  color: rgb(92, 92, 92);
  font-weight: 600;
  font-size: 1.1em;
  text-decoration: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.check-grp {
  display: flex;
  align-content: center;
  align-items: center;
}

.grp {
  margin-bottom: 1em;
}

.check-grp {
  flex-basis: 200px;
}

.btn-err-wrapper {
  margin-top: 1em;
}

.err {
  margin-top: 20px;
  color: #e41515;
}

button {
  background-color: #e41515;
  color: white;
  padding: 10px 15px;
  font-size: 1em;
  border-radius: 12px;
  border: none;
  cursor: pointer;
}

button:hover {
  box-shadow: 0 0 10px 0 gray;
}

button:disabled {
  background-color: gray;
}

button:disabled:hover {
  box-shadow: none;
  cursor: unset;
}

.logo-wrapper {
  text-align: center;
}

img {
  max-width: 50%;
}


.success-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 5em;
  box-shadow: 0 0 10px 0 gray;
  width: 500px;
  background-color: white;
  margin: auto;
  border-radius: 15px;
}

h3 {
  font-size: xx-large;
}

.timer {
  font-size: larger;
}

@media (min-width: 1501px) and (max-width: 2000px) {}

@media (min-width: 1321px) and (max-width: 1500px) {}

@media (min-width: 1201px) and (max-width: 1320) {}

@media (min-width: 992px) and (max-width: 1200) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {

  .success-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 5%;
    box-shadow: 0 0 10px 0 gray;
    width: 80%;
    background-color: white;
    margin: auto;
    padding-bottom: 3em;
  }

  .pass-upate-form {
    padding: 5%;
    width: 80%;
  }

  .form-control {
    width: 80%;
  }
}